import * as React from "react";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../components/pages/personal/PersonalBody";
import {Button, Form, Input, message} from "antd";
import FormItem from "antd/es/form/FormItem";
import DarkButton from "../../../components/buttons/darkButton";
import auth from "../../../utils/auth";
import SmsInput from "../../../components/pages/personal/authentication/SmsInput";
import Seo from "../../../components/seo";
import {useEffect, useState} from "react";
import jsonRequest from "../../../utils/request/jsonRequest";
import {useSelector} from "react-redux";
import {navigate} from "gatsby";

const PersonalAuthentication = () => {
  auth.authorize()
  const {account} = useSelector((s: any) => s)
  const [form] = Form.useForm()
  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [submitting, setSubmitting] = useState(false)
  const handleValuesChange = (_, values) => {
    console.log(values)
    setPhone(values.phone ?? "")
  }
  const handleFinish = (values) => {
    if (submitting) {
      return
    }
    setSubmitting(true)
    jsonRequest("/account/auth", {
      method: 'POST',
      data: values,
    }).then(rsp => {
      if (rsp.success) {
        message.success('认证成功')
        navigate("/personal/resume")
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setSubmitting(false)
    })
  }
  const load = () => {
    if (loading || !account) {
      return;
    }
    setLoading(true)
    jsonRequest("/account/auth").then(rsp => {
      if (rsp.success) {
        setData(rsp.data.data)
        handleValuesChange("", rsp.data.data)
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    load()
  }, [account])
  return (<>
    <PersonalLayout selected='resume' loading={loading}>
      <PersonalBody
        title='实名认证'
        backLink='/personal/resume'
        paddingTop={28}
      >
        {data && (
          <Form
            labelCol={{span: 6}}
            wrapperCol={{span: 12}}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
            {...{form}}
            initialValues={data}
          >
            <FormItem
              label='真实姓名'
              name='realName'
              required={false}
              rules={[{required: true, message: '请填写真实姓名'}]}
            >
              <Input />
            </FormItem>
            <FormItem
              label='身份证号'
              name='identification'
              required={false}
              rules={[{required: true, message: '请填写身份证号'}]}
            >
              <Input />
            </FormItem>
            {/*<FormItem*/}
            {/*  label='微信号码'*/}
            {/*  name='weChat'*/}
            {/*>*/}
            {/*  <Input />*/}
            {/*</FormItem>*/}
            {/*<FormItem*/}
            {/*  label='邮箱地址'*/}
            {/*  name='email'*/}
            {/*>*/}
            {/*  <Input />*/}
            {/*</FormItem>*/}
            <FormItem
              label='手机号'
              name='phone'
              required={false}
              rules={[{required: true, message: '请填写手机号'}]}
            >
              <Input  />
            </FormItem>
            <FormItem
              label='验证号码'
              name='code'
              required={false}
              rules={[{required: true, message: '请填写验证码'}]}
            >
              {/*<Input*/}
              {/*  suffix={(*/}
              {/*    <Button type='link' size='small'>获取验证码</Button>*/}
              {/*  )}*/}
              {/*/>*/}
              <SmsInput phone={phone}/>
            </FormItem>
            <FormItem wrapperCol={{offset: 6}}>
              <DarkButton type='primary' onClick={form.submit} loading={submitting}>提交认证</DarkButton>
            </FormItem>
          </Form>
        )}
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default PersonalAuthentication;

export const Head = () => <Seo />
