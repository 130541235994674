import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Input, message, Space} from "antd";
import jsonRequest from "../../../../utils/request/jsonRequest";

const defaultText = "发送验证码"

const getCountDownTest = (count) => {
  return `请等待${count}秒`
}
const phoneRegex = /^1\d{10}$/

const SmsInput = (props: any) => {
  const {value, phone, onChange = () => {}} = props
  const [sending, setSending] = useState(false)
  const [sand, setSand] = useState(false)
  const [countdownTime, setCountdownTime] = useState(0)

  const handleSend = () => {
    if (!phone) {
      message.error("请输入手机号")
      return ;
    }
    if (!phone.match(phoneRegex)) {
      message.error("手机号格式错误")
      return;
    }
    if (sending || sand) {
      return ;
    }
    setSending(true)
    jsonRequest("/account/auth_sms", {
      method: 'POST',
      data: {
        phone,
      }
    }).then((rsp) => {
      if (rsp.success) {
        message.success('短信发送成功');
        setSand(true);
        setCountdownTime(60)
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => {
      setSending(false)
    })
  }

  useEffect(() => {
    if (sand) {
      let time = 60;
      const interval = setInterval(() => {
        setCountdownTime(time--)
        if (time <= 0) {
          setSand(false)
          clearInterval(interval)
        }
      }, 1000)
      return () => {
        setSand(false)
        clearInterval(interval)
      }
    }
  }, [sand])

  return (
    <Input
      onChange={onChange}
      value={value}
      suffix={sand && (
        <span style={{color: '#a3a3a3'}}>
          {getCountDownTest(countdownTime)}
        </span>
      ) || (
        <Button
          type='link'
          size='small'
          loading={sending}
          onClick={handleSend}
        >{defaultText}</Button>
      )}
    />
  )

  return (<>
    <Space direction="horizontal" style={{width: '100%'}}>
      <Input
        placeholder="短信验证码"
        size='large'
        value={value}
        onChange={onChange}
      />
      <Button
        style={{
          width: 120,
          fontSize: 14,
          fontFamily: 'PingFang SC-Regular, PingFang SC',
          color: sand ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.8)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        loading={sending}
        size='large'
        onClick={handleSend}
      >
        {sand && getCountDownTest(countdownTime)}
        {!sand && defaultText}
      </Button>
    </Space>
  </>)
}

export default SmsInput
